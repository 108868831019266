<template>
	<div class="PageBox" >
		<div class="bgc">
			<div class="topTitle">{{$t('ViewWithdrawalInformation[0]')}}</div>
		</div>
		<div class="box">
			<div class="information">
				<p v-if="drawList[active]">{{currency}}{{$t('ViewWithdrawalInformation[1]')}}</p>
				<input v-model="card_no"/>
			</div>
			<!-- <p class="platform">{{$t('bindcoin[1]')}}</p>
			<a class="toRegister" @click="$router.push('/register')">{{$t('bindcoin[2]')}}</a>
			<div class="selectBox">
				<div class="select" v-if="drawList[active]">
					<p :class="{'active':active==index}"  v-for="(item,index) in drawList" :key="index" @click="upActive(index)">{{item}}</p>
				</div>
			</div>
			<div class="informationBox">
				<p class="information-title">{{$t('bindcoin[6]')}}</p>
				<div class="information">
					<p v-if="drawList[active]">{{currency}}{{$t('bindcoin[7]')}}:</p>
					<input v-model="card_no"/>
				</div>
			</div>-->
			<div class="QRBox">
				<div class="upload">
					<p class="QR-title">{{$t('ViewWithdrawalInformation[2]')}}</p>
					<van-uploader v-model="imgList[0]" max-count="1" :deletable="false">
						<img class="btnImg" src="../../../public/static/images/shangChuanErWeiMa.png">
					</van-uploader>
				</div>
			</div>
		</div>
		<div class="description" :class="{'letter_s':language1}">
			<p class="title">{{$t('ViewWithdrawalInformation[3]')}}</p>
			<p class="p">1.{{$t('ViewWithdrawalInformation[4]')}}</p>
			<p class="p">2.{{$t('ViewWithdrawalInformation[5]')}}</p>
			<p class="p">3.{{$t('ViewWithdrawalInformation[6]')}}</p>
			<p class="p">4.{{$t('ViewWithdrawalInformation[7]')}}</p>
		</div>
		<van-loading class="Loading" size="50" vertical v-if="isLoad">{{$t('recharge.default[8]')}}</van-loading>
	</div>
</template>

<script>
export default {
  name: 'Wallet',
  components: {
	  
  },
  props: ['walletType'],
  data() {
    return {
      listData: '',
      isLoad: true,
      isFinished: false,
      isRefresh: false,
      pageNo: 1,
      tabsState: 1,
      tabsIndex: 0,
      taskTabs: [
        {type: 1, text: this.$t('wallet.default[3]')},
        // {type: 2, text: '转账记录'},
        {type: 3, text: this.$t('wallet.default[4]')}
      ],
      showTurn: false,
      showDraw: false,
      showPicker: false,
      selectBank: '',
      isSubmit: false,
      turnData: {
        username: '',
        turn_money: '',
        drawword: ''
      },
      drawData: {
        draw_type: 'bank',
        user_bank_id: '',
        draw_money: 100,
        // phone: '',
        email: '',
        // ifsc: '',
        drawword: ''
      },
	  //上传图片
	  imgList: [],
	  rechargeList:[],//交易信息
	  upiData:{},//应该是我的信息
	  active:0,//点击的哪个充值平台
	  drawList:[],//提现信息
	  card_no:'',//提现地址
	  bankInfo:{},//我的绑定信息
	  currency:'',
	  language1:localStorage.getItem('Language')=='cn',
    }
  },
  computed: {
    
  },
  created() {
	this.$parent.navBarTitle = ''
	this.GetWithdrawType()
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
	  deleteImg(){
		  console.log(1)
		  this.imgList=[]
	  },
	//提交绑定
	submitInfo(){
		if(!this.card_no){
			this.$Dialog.Toast('The withdrawal address cannot be empty');
			return false
		}
		let json={
			uid:this.UserInfo.userid,
			currency:this.drawList[this.active].currencies[0],
			card_no:this.card_no,
			bank_code:this.drawList[this.active].file_name,
			draw_cfg_id:this.drawList[this.active].id,
		}
		if(this.imgList[0]){
			if(this.imgList[0][0].url||this.imgList[0][0].length>3){
				json.qr_code=this.imgList[0][0].url
			}else if(this.imgList[0].length>3){
				json.qr_code=this.imgList[0]
			}
		}
		console.log(json)
		this.$Model.ChangeBankCardInfo(json,(data)=>{
			this.$Dialog.Toast('success');
		})
	},
	//绑定信息
	GetWithdrawType(){
		this.$Model.GetWithdrawType(data=>{
			console.log(data)
			this.isLoad = false
			//字符串转数组
			data.info.forEach(item=>{
				item.currencies=item.currencies.split(',')
			})
			if(data.bank.data.bank_code){
				data.info.forEach((item,index)=>{
					if(item.file_name==data.bank.data.bank_code){
						this.active=index
					}
				})
				//我的绑定信息
				this.bankInfo=data.bank.data
				//银行卡账号
				this.card_no=this.bankInfo.card_no
				let url=[]
				if(!data.bank.data.qr_code){
					url[0]={url:'https://img.yzcdn.cn/vant/leaf.jpg'}
					this.imgList[0]=url
				}
			}
			//绑定什么平台
			this.drawList[0] = data.bank.data.bank_code
			this.currency = data.bank.data.currency
		})
		// this.isLoad = false
	},
	//修改绑定平台active
	upActive(index){
	   this.active=index
	},
	//上传图片回调
	afterRead(file) {
	  file.status = 'uploading'
	  file.message = this.$t('upload[0]')
	  this.uploadImgs(file)
	},
	uploadImgs(file){
	    if (!file.file.type.match(/image/)){
	      file.status = 'failed'
	      file.message = this.$t('upload[1]')
	      return;
	    }
	    this.compressImg(file)
	},
	compressImg(file) {
	  this.$Util.CompressImg(file.file.type,file.content,750,(image)=>{
	    let param = new FormData();
	    param.append('token',localStorage['Token']);
	    param.append('type',3);
	    param.append('image',image,file.file.name);
	    this.$Model.UploadImg(param,(data)=>{
	      if(data.code==1){
	        file.message = this.$t('upload[2]')
	        file.status = 'success'
	        file.url = data.url
			this.imgList[0]=data.url
	      }else{
	        file.status = 'failed'
	        file.message = this.$t('upload[3]')
	      }
	    });
	  })
	},
  }
}
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
.PageBox{
	overflow: auto;
}
.PageBox .bgc {
	width: 100%;
	background-color: #4E51BF;
	height: 46px;
	position: fixed;
	top: 0;
	z-index: 200;
}

.topTitle {
	position: absolute;
	max-width: 60%;
	white-space: nowrap;
	font-size: 18px;
	color: #fff;
	background-color: transparent;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
}
.PageBox .box{
	padding:0 19px;
	font-size: 19px;
	margin-top:30px;
	color:#555;
}
.box .toRegister{
	font-size: 14px;
	color:#c9c9c9;
	text-transform: uppercase;
	border-bottom: 1px solid #c9c9c9;
}
.box .selectBox{
	border-bottom: 1px dashed #c9c9c9;
}	
.box .select{
	/* width:; */
	max-width: 400px;
	display: flex;
	margin: 40px 0;
	flex-wrap: wrap;
}
.box .select p{
	padding:10px 15px;
	border-radius: 4px;
	text-align: center;
	font-size: 16px;
	border: 1px solid #0f25fe;
	color: #0f25fe;
	margin:0 25px 12px 0;
}
.box .select p:last-child{
	margin-right: 0;
}
p.active{
	background-color: #0f25fe !important;
	color: #fff !important;
}
.informationBox{
	border-bottom: 1px dashed #c9c9c9;
}
.information-title{
	margin:40px 0 34px 0;
}
.information{
	display: flex;
	font-size: 14px;

	align-items: center;
	max-width:550px;
	background: #EFF0F2;
	border-radius: 10px;
	overflow: hidden;
	padding:0 15px;
}
.information p{
	color:#000;
	display: flex;
	align-items: center;
	width:110px;
	word-wrap:break-word;
}
.information input{
	flex:1;
	background: #EFF0F2;
	height: 30px;
	line-height: 40px;
	border: none;
	border-radius: 4px;
	border: none;
	font-size: 13px;

	font-weight: normal;
	color: #626262;
	position: relative;
}
input::-webkit-input-placeholder{
	position: absolute;
	top:50%;
	transform: translateY(-50%);
}
.QRBox .upload{
	display: flex;
	align-items: center;
	margin-top: 35px;
	margin-left: 15px;
}
.QRBox .QR-title{
	 font-size:14px;
	 color: #000;

	 font-weight: normal;
	 margin-right: 25px;
}
.QRBox .upload .van-uploader>>> .btnImg{
	width:130px;
	height: 130px;
	padding:30px;
	border: 1px solid #c6c7c6;
	margin-top: 15px;
	border-radius: 4px;
}
.van-uploader>>>.van-uploader__preview{
	margin: 15px 0 0 0;
}
.van-uploader>>>.van-image{
	width:140px;
	height: 140px;
}
.QRBox .upload .btn{
	padding:8px 10px;
	border-radius: 6px;
	background-color: #0f25fe;
	color:#fff;
	margin: 41px 0 70px 0;
}
.description{
	font-size: 18px;
	color: #555;
	background-color: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	padding:40px 33px;
	border-radius: 20% 20% 0 0;
	position: relative;
	margin-top: 125px;
}
.description p{
	color:#000;
	font-size: 13px;
	margin-bottom: 8px;
	line-height: 2.1;

	font-weight: normal;
}
.letter_s{
	letter-spacing: 0.04rem;
}
.description p:nth-child(2){
	margin-top: 10px;
}
.description .title{
	font-size: 14px;
	position: absolute;
	top:0px;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index:1;
	width:200px;
	padding:5px 0;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	box-sizing: border-box;
	background-color: #fff;
	text-align: center;
}
.van-nav-bar__title{
	color: #fff !important;
}

.van-loading{
	position: fixed;
	z-index: 99999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
